import React from "react"
//import { Link } from "gatsby"
import { Link, Fab } from "gatsby-theme-material-ui";
import { Typography, Button } from "@material-ui/core";

import AniLink from "gatsby-plugin-transition-link/AniLink"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import BtnAddShow from "../components/btnaddshow"


const Counter = ({ count, decrement }) => (
  <div>
    <p>Count: {count}</p>
    <Button color="primary" size="small" variant="contained" onClick={decrement}>Decrement</Button>
  </div>
)

Counter.propTypes = {
  count: PropTypes.number.isRequired,
  decrement: PropTypes.func.isRequired,
}

const mapStateToProps = ({ count }) => {
  return { count }
}

const mapDispatchToProps = dispatch => {
  return { decrement: () => dispatch({ type: `DECREMENT` }) }
}

const ConnectedCounter = connect(mapStateToProps, mapDispatchToProps)(Counter)

const IndexPage = () => (
  <div>
    <SEO title="Home" />
    <h1>Hi Yo</h1>
    <p>Welcome to my new site.</p>
    <ConnectedCounter />
    
    <Typography>
    <Link to="/page-2/">Go to page 2</Link> 
    </Typography>
    <br />
    <AniLink swipe to="/using-typescript/">Go to "Using TypeScript"</AniLink>
    <BtnAddShow></BtnAddShow>
  </div>
)

export default IndexPage
